<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>Выданные промо-коды</v-card-title>
          <v-card-text>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Код</th>
                    <th>Дата выдачи</th>
                    <th>TG USER</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(code, i) in promoCodes" :key="i">
                    <td>{{ code.id }}</td>
                    <td>{{ code.code }}</td>
                    <td>{{ code.created_at }}</td>
                    <td>{{ code.tg_user_id }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { computed } from '@vue/composition-api'
import store from '@/store'

export default {
  name: 'PromoCodes',
  setup(){
    const promoCodes = computed(()=>store.state.promoCodes)
    return{
      promoCodes
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>
